import Axios from "axios"
import { URL_POST_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE, URL_POST_REPORT_TEAM_TODAY, URL_POST_REPORT_TEAM_TODAY_BY_CREATEDATE } from "../../config/DomainConfig"
import { convertTime, getNumberFixed, getNumberWithCommas, getValWithPercentHtml } from "../UtilService"
import { getNorm, NORM_PROPERTIES } from "./PropertyConvertion"

const callTeamTodayReport = async (payload) => {
    let data = []
    const resp = await Axios.post(URL_POST_REPORT_TEAM_TODAY(), payload)

    // const resp = await Axios.get("/payload/team_today.json")

    if (resp && resp.data && resp.data.teamTodayEntities) {
        data = resp.data.teamTodayEntities
        data = getNorm(data, NORM_PROPERTIES)
        // data = buildHierarchy(resp.data.teamTodayEntities)
        // sumHierarchy(data)
        // calcHierarchy(data)
    }

    return { data: data, created: resp.data.created }
}
const callTeamTodayByCreatedateReport = async (payload) => {
    let data = []
    const resp = await Axios.post(URL_POST_REPORT_TEAM_TODAY_BY_CREATEDATE(), payload)

    if (resp && resp.data && resp.data.teamTodayByCreatedateEntities) {
        data = resp.data.teamTodayByCreatedateEntities
        data = getNorm(data, NORM_PROPERTIES)
        // data = buildHierarchy(resp.data.teamTodayByCreatedateEntities)
        // sumHierarchy(data)
        // calcHierarchy(data)
    }

    return { data: data, created: resp.data.created }
}

const callTeamProdTodayByCreatedateReport = async (payload) => {
    let data = []
    const resp = await Axios.post(URL_POST_REPORT_TEAM_PROD_TODAY_BY_CREATEDATE(), payload)

    if (resp && resp.data && resp.data.teamProdTodayByCreatedateEntities) {
        data = resp.data.teamProdTodayByCreatedateEntities
        data.forEach(row => {
            row.cpName = row.prodName
            row.cpId = row.prodId
        })
        data = getNorm(data, NORM_PROPERTIES)
        // data = buildHierarchy(resp.data.teamProdTodayByCreatedateEntities)
        // sumHierarchy(data)
        // calcHierarchy(data)
    }

    return { data: data, created: resp.data.created }
}

const buildHierarchy = (teamTodayByCreatedateEntities) => {
    let data = {}
    let teamId, teamName, cpCat, userId

    teamTodayByCreatedateEntities.forEach(row => {
        teamId = row.teamId ? row.teamId : 0
        teamName = row.teamName ? row.teamName : "No team"
        cpCat = row.cpCat
        userId = row.userId

        if (!data[teamId]) {
            data[teamId] = {
                key: [teamId],
                name: teamName,
                cate: {}
            }
        }

        if (!data[teamId].cate[cpCat]) {
            data[teamId].cate[cpCat] = {
                key: [teamId, cpCat],
                name: row.cpCatName,
                agent: {}
            }
        }

        if (!data[teamId].cate[cpCat].agent[userId]) {
            data[teamId].cate[cpCat].agent[userId] = {
                key: [teamId, cpCat, userId],
                name: row.userName,
                campaign: []
            }
        }

        row.key = [teamId, cpCat, userId, row.cpId]
        data[teamId].cate[cpCat].agent[userId].campaign.push(row)
    })

    return data
}

const sumHierarchy = (data) => {
    // const sumFields = ["totalLead", "approved", "rejected"
    //     , "trash", "urgent", "delivered"
    //     , "callback", "uncall", "totalSoAmount"
    //     , "delivSaleOrder", "delivAmount"]
    let myFields
    let sumFields

    Object.keys(data).forEach(teamId => {
        Object.keys(data[teamId].cate).forEach(cpCat => {
            Object.keys(data[teamId].cate[cpCat].agent).forEach(userId => {
                if (!sumFields) {
                    myFields = Object.keys(data[teamId].cate[cpCat].agent[userId].campaign[0])
                    sumFields = Object.keys(NORM_PROPERTIES).filter(fld => myFields.indexOf(fld) > -1).map(fld => NORM_PROPERTIES[fld])
                        .filter(fld => myFields.indexOf(fld) > -1)
                }
            })
        })
    })

    Object.keys(data).forEach(teamId => {
        sumFields.forEach(field => {
            data[teamId][field] = 0
        })

        Object.keys(data[teamId].cate).forEach(cpCat => {

            sumFields.forEach(field => {
                data[teamId].cate[cpCat][field] = 0
            })

            Object.keys(data[teamId].cate[cpCat].agent).forEach(userId => {

                sumFields.forEach(field => {
                    data[teamId].cate[cpCat].agent[userId][field] = 0
                })

                data[teamId].cate[cpCat].agent[userId].campaign.forEach(cp => {

                    sumFields.forEach(field => {
                        data[teamId][field] += cp[field] ? cp[field] : 0
                        data[teamId].cate[cpCat][field] += cp[field] ? cp[field] : 0
                        data[teamId].cate[cpCat].agent[userId][field] += cp[field] ? cp[field] : 0
                    })
                })
            })
        })
    })
}

const calcHierarchy = (data) => {
    Object.keys(data).forEach(teamId => {

        calcHierarchyEle(data[teamId])

        Object.keys(data[teamId].cate).forEach(cpCat => {

            calcHierarchyEle(data[teamId].cate[cpCat])

            Object.keys(data[teamId].cate[cpCat].agent).forEach(userId => {

                calcHierarchyEle(data[teamId].cate[cpCat].agent[userId])

                data[teamId].cate[cpCat].agent[userId].campaign.forEach(cp => {

                    calcHierarchyEle(cp)
                })
            })
        })
    })
}

const calcHierarchyEle = (_en) => {

    const _totalLead = _en.totalLead
    const _new = _en.new
    const _ttt = _en.totalTalkTimeInSeconds ? _en.totalTalkTimeInSeconds : 0
    const _approved = _en.approved
    const _rejected = _en.rejected
    const _trash = _en.trash
    const _urgent = _en.urgent
    const _delivered = _en.delivered
    const _callback = _en.callback
    const _uncall = _en.uncall
    const _totalSoAmount = _en.totalSoAmount
    const _delivSaleOrder = _en.delivSaleOrder
    const _delivAmount = _en.delivAmount
    const _usr = _en.cpName ? _en.cpName : (_en.name ? _en.name.split("@")[0] : "-")
    let _ear = _approved * 100 / (_approved + _rejected + _callback)
    let _aov = _totalSoAmount / _approved
    let _daov = _delivAmount / _delivSaleOrder

    _ear = isNaN(_ear) ? 0 : _ear
    _aov = isNaN(_aov) ? 0 : _aov
    _daov = isNaN(_daov) ? 0 : _daov

    _en["_name"] = { "__html": _usr }
    _en["_leads"] = { "__html": getNumberWithCommas(_totalLead) + "" }
    _en["_sale"] = { "__html": getNumberWithCommas(_totalSoAmount) }
    _en["_ttt"] = { "__html": convertTime(_ttt) }
    _en["_new"] = { "__html": getValWithPercentHtml(_new, _totalLead) }
    _en["_approved"] = { "__html": getValWithPercentHtml(_approved, _totalLead) }
    _en["_ear"] = { "__html": getNumberFixed(_ear, 0) + "%" }
    _en["_aov"] = { "__html": getNumberFixed(_aov, 0) }
    _en["_rejected"] = { "__html": getValWithPercentHtml(_rejected, _totalLead) }
    _en["_callback"] = { "__html": getValWithPercentHtml(_callback, _totalLead) }
    _en["_uncall"] = { "__html": getValWithPercentHtml(_uncall, _totalLead) }
    _en["_trash"] = { "__html": getValWithPercentHtml(_trash, _totalLead) }
    _en["_urgent"] = { "__html": getValWithPercentHtml(_urgent, _totalLead) }
    _en["_deliver"] = { "__html": getValWithPercentHtml(_delivered, _totalLead) }
    _en["_dsale"] = { "__html": getNumberFixed(_delivAmount, 0) }
    _en["_dr"] = { "__html": getValWithPercentHtml(_delivSaleOrder, _approved) }
    _en["_daov"] = { "__html": getNumberFixed(_daov, 0) }
    _en["ear"] = _ear
    _en["aov"] = _aov
    _en["daov"] = _daov
}

const extractTeamAgent = (ds, t0, sort, sortIdx) => {
    return Object.keys(ds[t0].cate).sort((ct1, ct2) => ct1 - ct2)
        .map(ct0 => {
            const category = ds[t0].cate[ct0]
            const agents = Object.keys(category.agent).sort((ag1, ag2) => {
                const sortField = sortIdx[sort.field]
                const val1 = category.agent[ag1][sortField]
                const val2 = category.agent[ag2][sortField]
                let diff

                if (isNaN(val1) || isNaN(val2)) {
                    diff = (val1 ? '' + val1 : '').localeCompare(val2 ? '' + val2 : '')
                } else {
                    diff = val1 - val2
                }

                return sort.direction === "asc" ? diff : diff * -1
            }).map(ag0 => {
                const agent = category.agent[ag0]

                const campaigns = Object.keys(agent.campaign).sort((cp1, cp2) => {
                    return agent.campaign[cp2].totalLead - agent.campaign[cp1].totalLead
                }).map(cp0 => {
                    const campaign = agent.campaign[cp0]

                    return campaign
                })

                return [agent, ...campaigns]
                // return agent
            }).reduce((prAg, nxAg) => prAg.concat(nxAg))

            return [category, ...agents]
        }).reduce((prCt, nxCt) => prCt.concat(nxCt))
}

export {
    callTeamTodayReport, buildHierarchy, sumHierarchy,
    callTeamTodayByCreatedateReport, callTeamProdTodayByCreatedateReport, extractTeamAgent,
    calcHierarchy, calcHierarchyEle
}