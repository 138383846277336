import React, { useEffect } from 'react'
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { callPostReportPubPerformanceMargin, mergePubSource } from '../../../services/reports/PubPerformanceReportService'
import { callGetStatisticReportPubPerformance } from '../../../services/statisticReports/PubPerformanceStatisticReportService'
import { FORMAT_NUMBER_M, getDateFormat, getNumberFixed, getNumberFormat, getNumberWithCommas, getPageCustomConfig, getPageOrg, getRedisDateFormat, resetWindow } from '../../../services/UtilService'
import PubPerformanceReportMargin from '../../Report/PubPerformanceReport/PubPerformanceReportMargin'
import { DS_SOURCE } from '../../Report/PubPerformanceReport/PubPerformanceReportConfig'
import "../../Report/PubPerformanceReport/PubPerformanceReport.css"
import TableComponent from '../../../component/TableComponent/TableComponent'
import { setStorage } from '../../../services/StorageService'
import diamondIcon from '../../../css/diamond.svg';
import { ROLE_ADV, ROLE_DIRECTOR, ROLE_MANAGER } from '../../../config/CommonConfig'

const pageOrg = getPageOrg()
const NUMBER_FORMAT = { th: "", vn: FORMAT_NUMBER_M, id: FORMAT_NUMBER_M }
const getDetails = (ds, selected) => {
    let i = 1;
    let rslt = ds.filter(row => row.prodId === selected.id)[0].sources
    rslt = Object.keys(rslt).map(term => rslt[term]).reduce((basket, cur) => { return basket.concat(cur) }, [])
    rslt = mergePubSource(rslt, ["EW"])
    rslt.sort((a, b) => { return b.leads - a.leads })

    rslt = rslt.map(src => {
        return {
            _no: { "__html": i++ },
            _pub: { "__html": src.pub },
            _leads: { "__html": getNumberWithCommas(src.leads) },
            _aov: { "__html": src.leads > 0 && getNumberFormat(NUMBER_FORMAT[pageOrg], src.aov, 0) },
            _approved: { "__html": getNumberFixed(src.approvedPct * 100, 0) + "%" },
            _validated: { "__html": getNumberFixed(src.validatedPct * 100, 0) + "%" },
            _trash: { "__html": getNumberFixed(src.trashPct * 100, 0) + "%" }
        }
    })

    return rslt
}

const PubPerformanceStatisticReport = (props) => {
    const { popLeadDist } = props
    const [query, setquery] = useState({ valid: false, dateFrom: undefined, dateTo: undefined })
    const [ds, setDs] = useState([])
    const [marginRules, setmarginRules] = useState({})
    const SOURCE_LIMIT = 10
    const customConfig = getPageCustomConfig()
    const isMore = customConfig.more || popLeadDist
    const maxDate = new Date()
    const minDate = new Date()
    const [selected, setselected] = useState({ id: undefined, name: undefined })
    const role = localStorage.getItem("role")

    minDate.setDate(minDate.getDate() - 31)

    const selectProduct = (e, id, name) => {
        e.preventDefault()
        setselected({ id: id, name: name })
    }

    const onQueryChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        query[name] = value
        query.valid = query.dateFrom !== undefined &&
            query.dateTo !== undefined &&
            query.dateFrom.getTime() <= query.dateTo.getTime()
        setquery({ ...query })
    }

    const onSearch = () => {
        const dateFrom = getDateFormat(query.dateFrom, "yyyyMMdd")
        const dateTo = getDateFormat(query.dateTo, "yyyyMMdd")
        const services = [callGetStatisticReportPubPerformance(dateFrom, dateTo)]
        Promise.all(services).then(resp => {
            setDs(resp[0].pubPerformanceEntities)
        })
    }

    // const getMarginCls = (sourceId, prodId, terms, val) => {
    //     let cls = 'qualified'
    //     const rule = getMarginRule(sourceId, prodId, terms, val)

    //     if (rule) {
    //         cls = rule.rule
    //     }

    //     return cls
    // }

    // const getMarginRule = (sourceId, prodId, terms, val) => {
    //     let validRule = undefined
    //     let _sourceId, _prodId, _terms
    //     let _rule
    //     const allRules = [
    //         [sourceId, prodId, terms],
    //         ['0', prodId, terms],
    //         [sourceId, '0', terms],
    //         [sourceId, prodId, '0'],
    //         [sourceId, '0', '0'],
    //         ['0', prodId, '0'],
    //         ['0', '0', terms],
    //         ['0', '0', '0']
    //     ]

    //     for (let i = 0; marginRules && i < marginRules.length && validRule === undefined; i++) {
    //         _rule = marginRules[i]

    //         for (let j = 0;
    //             j < allRules.length && allRules[j][0] && validRule === undefined;
    //             j++) {
    //             _sourceId = allRules[j][0].toString()
    //             _prodId = allRules[j][1].toString()
    //             _terms = allRules[j][2].toString()

    //             if (_rule.sourceId === _sourceId &&
    //                 _rule.prodId === _prodId &&
    //                 _rule.terms === _terms &&
    //                 (_rule.min <= val && val < _rule.max)) {
    //                 validRule = _rule
    //             }
    //         }
    //     }

    //     return validRule;
    // }

    const popLeadDistpage = (prodName, params) => {
        const title = prodName + " " + params.pub
        const prodId = params.prodId ? params.prodId : "0"
        const terms = params.terms ? params.terms : "0"
        const sourceId = params.sourceId ? params.sourceId : "0"
        const keys = prodId + terms + sourceId
        const dateFrom = getDateFormat(query.dateFrom, "yyyyMMdd")
        const dateTo = getDateFormat(query.dateTo, "yyyyMMdd")

        setStorage(keys, title)

        window.open("/statisticReport/leadDistToday/" + pageOrg + "/" +
            dateFrom + "/" +
            dateTo + "/" +
            prodId + "/" +
            terms + "/" +
            sourceId)
    }

    useEffect(() => {
        const services = [callPostReportPubPerformanceMargin()]
        Promise.all(services).then(resp => {
            setmarginRules(resp[0].pubPerformanceRuleModels)
            document.getElementById("redisDateRule").innerHTML = "Last updated at " + getRedisDateFormat(resp[0].created)
        })
        resetWindow()
    }, [])

    return (<React.Fragment>
        {selected.id !== undefined && <div className="static-modal">
            <div className="static-modal-content">
                <div className="container">
                    <button className="btn btn-danger float-right"
                        onClick={e => setselected({ id: undefined, name: undefined })}>
                        <i className="fa fa-window-close"></i>
                    </button>
                    <h4>{selected.name}</h4>
                    <div className="mt-5 pre-scrollable">
                        <TableComponent
                            className="col-12"
                            dataset={DS_SOURCE}
                            dataSource={getDetails(ds, selected)}>
                        </TableComponent>
                    </div>
                </div>
            </div>
        </div>}

        <div className="form-row">
            <div className="form-group col-md-6">
                <label htmlFor="fltDateFrom">Date From</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateFrom"
                        name="dateFrom"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateFrom", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
            <div className="form-group col-md-6">
                <label htmlFor="fltDateTo">Date To</label>
                <div className="form-control border-0">
                    <ReactDatePicker id="fltDateTo"
                        name="dateTo"
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={query.dateTo}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => onQueryChange({ target: { name: "dateTo", value: date } })}
                        className="form-control"></ReactDatePicker>
                </div>
            </div>
        </div>
        {query.valid && <div className="form-row justify-content-end mb-3">
            <button className="btn btn-success" onClick={e => onSearch()}>Search</button>
        </div>}

        <div className="card mb-4">
            <div className="card-body">
                <div className={`scroll-x wallboard pub-performance-report ${customConfig && isMore ? 'statistic' : ''}`}>
                    {ds && ds.map(row => {
                        return Object.keys(row.sources).sort((a, b) => b - a).map(terms => {
                            const prodId = row.prodId

                            return (<div key={[prodId, terms]} className="wb-row" data-prod-id={prodId} data-terms={terms}>
                                <div className="wb-col">
                                    <div className={"card head border-0 text-center" + (customConfig.more ? " more" : "")}>
                                        <div className="card-body">
                                            <p>
                                                <a href="/#" className="mr-1" onClick={e => selectProduct(e, prodId, row.name[terms])}>{row.name[terms]}</a>
                                            </p>
                                            <p className="campaign">
                                                {row.cp[terms]}
                                            </p>
                                            <p>
                                                <span className="mr-1">{getNumberWithCommas(row.leads[terms])}</span>
                                                <span className="text-info">({getNumberFormat(NUMBER_FORMAT[pageOrg], row.amount[terms] / row.approved[terms], 0)})</span>
                                            </p>
                                            {/* {customConfig && customConfig.more && (<p>
                                                <span className="mr-1">u: {getNumberFixed(row.uncalled[terms] * 100 / row.leads[terms], 0)}%</span>
                                                <span className="">r: {getNumberFixed(row.rejected[terms] * 100 / row.leads[terms], 0)}%</span>
                                            </p>)} */}
                                            {customConfig && isMore && (<p className='sttCall'>
                                                <span className="mr-1">u: {getNumberFixed(row.uncalled[terms] * 100 / row.leads[terms], 0)}%</span>
                                                <span className="mr-1">r: {getNumberFixed(row.rejected[terms] * 100 / row.leads[terms], 0)}%</span>
                                                <span className="mr-1">cb: {getNumberFixed(row.callback[terms] * 100 / row.leads[terms], 0)}%</span>
                                                <span className="mr-1">ur: {getNumberFixed(row.urgent[terms] * 100 / row.leads[terms], 0)}%</span>
                                                <span className="">at: {getNumberFixed(row.avgUncalledClosedCall / row.attempt[terms].length, 1)}</span>
                                            </p>)}
                                            <p style={{fontSize: '20px'}}>
                                                [<span className="text-info">{getNumberFixed(row.approved[terms] * 100 / row.leads[terms], 0)}%</span>/
                                                <span className="text-success">{getNumberFixed(row.validated[terms] * 100 / row.leads[terms], 0)}%</span>
                                                {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <span className="text-success">({getNumberFixed(row.validatedPb?.[terms] * 100/ row.leads[terms], 0)}%)</span>}/
                                                <span className="text-danger">{getNumberFixed(row.trash[terms] * 100 / row.leads[terms], 0)}%</span>]
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {mergePubSource(row.sources[terms], ["EW"]).sort((a, b) => { return b.leads - a.leads }).map((src, idx) => {
                                    
                                    let targetAr = undefined

                                    if (src.arTargetPct) {
                                        targetAr = <span className=" txt-dark-yellow">AR_MRP: {src.arTargetPct}%</span>
                                    }

                                    return (
                                        <React.Fragment key={[src, idx]}>
                                            {idx < SOURCE_LIMIT && <div className="wb-col ml-1" data-source-id={src.sourceId}>
                                                {/* <div className={'card tail text-center cursor-pointer ' + (customConfig.more ? " more " : "") + getMarginCls(src.sourceId, prodId, terms, src.approvedPct * 100)} */}
                                                <div className={'card tail text-center cursor-pointer ' + (customConfig.more ? " more " : "") + (src.arGapType ? src.arGapType : "") + (src.partnerRank && src.partnerRank > 0 ? " highlightBorder" : "")}
                                                    onClick={e => { popLeadDistpage(row.name[terms], src) }}>
                                                    <div className="card-body">
                                                        <p>
                                                            <span className="mr-1">{src.pub}</span>
                                                            {
                                                                src.traffic && (
                                                                    <span className='traffic mr-1' style={{fontSize: '10px'}}>{src.traffic}</span>
                                                                )
                                                            }
                                                            {
                                                                src.partnerRank && src.partnerRank > 0 && (
                                                                    <span className='rank' style={{fontSize: '10px', display: 'inline-flex'}}>
                                                                        {src.partnerRank}
                                                                        <img src={diamondIcon} style={{marginLeft: '2px'}} alt="Diamond" />
                                                                    </span>
                                                                )
                                                            }
                                                        </p>
                                                        <p className="campaign">{targetAr}</p>
                                                        <p>
                                                            <span className="mr-1">{getNumberWithCommas(src.leads)}</span>
                                                            <span className="text-info">({src.leads > 0 && getNumberFormat(NUMBER_FORMAT[pageOrg], src.aov, 0)})</span>
                                                        </p>
                                                        {/* {customConfig && customConfig.more && (<p>
                                                            <span className="mr-1">u: {getNumberFixed(src.uncalledPct * 100, 0)}%</span>
                                                            <span className="">r: {getNumberFixed(src.rejectedPct * 100, 0)}%</span>
                                                        </p>)} */}
                                                        {customConfig && isMore && (<p className='sttCall'>
                                                            <span className="mr-1">u: {getNumberFixed(src.uncalledPct * 100, 0)}%</span>
                                                            <span className="mr-1">r: {getNumberFixed(src.rejectedPct * 100, 0)}%</span>
                                                            <span className="mr-1">cb: {getNumberFixed(src.callbackPct * 100, 0)}%</span>
                                                            <span className="mr-1">ur: {getNumberFixed(src.urgentPct * 100, 0)}%</span>
                                                            <span className="">at: {src.avgUncalledClosedCall}</span>
                                                        </p>)}
                                                        <p style={{fontSize: '20px'}}>
                                                            [<span className="text-info">{getNumberFixed(src.approvedPct * 100, 0)}%</span>/
                                                            <span className="text-success">{getNumberFixed(src.validated * 100 / src.leads, 0)}%</span>
                                                            {[ROLE_MANAGER, ROLE_DIRECTOR, ROLE_ADV].includes(role) && <span className="text-success">({getNumberFixed(src.validatedPb * 100 / src.leads, 0)}%)</span>}/
                                                            <span className="text-danger">{getNumberFixed(src.trashPct * 100, 0)}%</span>]
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>}
                                        </React.Fragment>
                                    )
                                })}
                            </div>)
                        })
                    })}
                </div>

                <PubPerformanceReportMargin marginRules={marginRules}></PubPerformanceReportMargin>


                <div className="row"><div id="redisDateRule" className="col-12 small text-right"></div></div>
            </div>
        </div>
    </React.Fragment>)
}

export default PubPerformanceStatisticReport