const NORM_PROPERTIES = {
    "totalDayLead": "totalLead",
    "totalTalkTimeInSeconds": "totalTalkTimeInSeconds",
    "newLead": "newLead",
    "dayNew": "new",
    "dayApproved": "approved",
    "dayDelivered": "delivered",
    "dayIntransit": "intransit",
    "dayReturned": "returned",
    "dayDelay": "delay",
    "dayValidated": "validated",
    "dayValidatedPb": "validatedPb",
    "dayRejected": "rejected",
    "dayUncall": "uncall",
    "dayCallback": "callback",
    "dayTrash": "trash",
    "dayUrgent": "urgent",
    "daySaleOrder": "saleOrder",
    "totalProduct": "totalProduct",
    "dayTotalSoAmount": "totalSoAmount",
    "upsellValue": "upsellValue",
    "upsellOrder": "upsellOrder",
    "totalDelivProduct": "totalDelivProduct",
    "dayDelivSaleOrder": "delivSaleOrder",
    "dayDelivAmount": "delivAmount",

    "totalMthLead": "totalLead",
    "mthNew": "new",
    "mthApproved": "approved",
    "mthDelivered": "delivered",
    "mthIntransit": "intransit",
    "mthReturned": "returned",
    "mthDelay": "delay",
    "mthValidated": "validated",
    "mthValidatedPb": "validatedPb",
    "mthRejected": "rejected",
    "mthUncall": "uncall",
    "mthCallback": "callback",
    "mthTrash": "trash",
    "mthUrgent": "urgent",
    "mthSaleOrder": "saleOrder",
    "mthTotalSoAmount": "totalSoAmount",
    "mthDelivSaleOrder": "delivSaleOrder",
    "mthDelivAmount": "delivAmount"
}

const getNorm = (data, NORM) => {
    let myFields
    let validFields

    if (data && data.length > 0) {
        myFields = Object.keys(data[0])
        validFields = Object.keys(NORM).filter(fld => myFields.indexOf(fld) > -1)

        data.forEach(row => {
            validFields.forEach(fld => {
                row[NORM[fld]] = row[fld]
            })
        })
    }

    return data
}

export {
    NORM_PROPERTIES,
    getNorm
}