import React from 'react';
import AgentMtdReport from '../page/Report/AgentMtdReport/AgentMtdReport';
import AgentTodayChart from '../page/Report/AgentTodayChart/AgentTodayChart';
import AgentMtdChart from '../page/Report/AgentMtdChart/AgentMtdChart';
import AgentTodayReport from '../page/Report/AgentTodayReport/AgentTodayReport';
import LeadDistChartMTD from '../page/Report/LeadDistChartMTD/LeadDistChartMTD';
import LeadDistMTD from '../page/Report/LeadDistMTD/LeadDistMTD';
import LeadDistChartToday from '../page/Report/LeadDistChartToday/LeadDistChartToday';
import LeadDistToday from '../page/Report/LeadDistToday/LeadDistToday';
import LeadDistTodayV2 from '../page/Report/LeadDistTodayV2/LeadDistTodayV2';
import LeadDistTodayV2Statistic from '../page/StatisticReport/LeadDistTodayV2/LeadDistTodayV2';
import FulfillmentStock from '../page/Report/FulfillmentStock/FulfillmentStock';
import TeamTodayReport from '../page/Report/TeamTodayReport/TeamTodayReport';
import TeamDistTodayReport from '../page/Report/TeamDistTodayReport/TeamDistTodayReport';
import TeamCampaignTodayReport from '../page/Report/TeamCampaignTodayReport/TeamCampaignTodayReport';
import TeamCampaignDistTodayReport from '../page/Report/TeamCampaignDistTodayReport/TeamCampaignDistTodayReport';
import RelPerformanceReport from '../page/Report/RelPerformanceReport/RelPerformanceReport';
import RelProdPerformanceReport from '../page/Report/RelProdPerformanceReport/RelProdPerformanceReport';
import TeamMtdReport from '../page/Report/TeamMtdReport/TeamMtdReport';
import TeamPerformanceReport from '../page/Report/TeamPerformanceReport/TeamPerformanceReport';

import RelPerformanceStatisticReport from '../page/StatisticReport/RelPerformanceStatisticReport/RelPerformanceStatisticReport';
import RelProdPerformanceStatisticReport from '../page/StatisticReport/RelProdPerformanceStatisticReport/RelProdPerformanceStatisticReport';
import PubPerformanceStatisticReport from '../page/StatisticReport/PubPerformanceStatisticReport/PubPerformanceStatisticReport';
import NetworkPerformanceStatisticReport from '../page/StatisticReport/NetworkPerformanceStatisticReport/NetworkPerformanceStatisticReport';
import LeadDistTodayStatisticReport from '../page/StatisticReport/LeadDistTodayStatisticReport/LeadDistTodayStatisticReport';
import AgentAllStatisticReport from '../page/StatisticReport/AgentAllStatisticReport/AgentAllStatisticReport';
import ByModifiedDateStatisticReport from '../page/StatisticReport/DailyStatisticReport/ByModifiedDateStatisticReport';
import ByCreateDateStatisticReport from '../page/StatisticReport/DailyStatisticReport/ByCreateDateStatisticReport';
import CampaignOverviewV2StatisticReport from '../page/StatisticReport/CampaignOverviewV2StatisticReport/CampaignOverviewV2StatisticReport';
import CampaignOverviewV3StatisticReport from '../page/StatisticReport/CampaignOverviewV3StatisticReport/CampaignOverviewV3StatisticReport';
// import AffiliateReport from '../page/BDReport/AffiliateReport/AffiliateReport';
import RescueSummaryReport from '../page/Report/RescueSummaryReport/RescueSummaryReport';
import DailyPubsPriority from '../page/Report/DailyPubsPriority/DailyPubsPriority';
import RescueCallReport from '../page/Report/RescueCallReport/RescueCallReport';
// import CampaignOverviewHSReport from '../page/HSReport/CampaignOverviewHSReport/CampaignOverviewHSReport';
// import DealHistoryHSReport from '../page/HSReport/DealHistoryHSReport/DealHistoryHSReport';
// import CampaignResellOverviewHSReport from '../page/HSReport/CampaignResellOverviewHSReport/CampaignResellOverviewHSReport';
// import CampaignDailyOverviewHSReport from '../page/HSReport/CampaignDailyOverviewHSReport/CampaignDailyOverviewHSReport';
import { ORG_ALL } from './CommonConfig';
import LeadDistTodayShift from '../page/Report/LeadDistToday/LeadDistTodayShift';
// import CampaignMonitoring from '../page/Cmdc/CampaignMonitoring/CampaignMonitoring';
// import AgentStatus from '../page/Cmdc/AgentStatus/AgentStatus';
// import AttemptAnalysis from '../page/Cmdc/AttemptAnalysis/AttemptAnalysis';
// import AgentSkillOverview from '../page/Cmdc/AgentSkillOverview/AgentSkillOverview';
import LogDO from '../page/Report/LogDO/LogDO';
// import FirstAttemptStatisticReport from '../page/StatisticReport/FirstAttemptStatisticReport/FirstAttemptStatisticReport'
// import UncallAnalysis from '../page/Cmdc/UncallAnalysis/UncallAnalysis'

// import { DOMAIN_API } from '../config/DomainConfig'
const Home = React.lazy(() => import("../page/Home/Home"));
const Wallboard = React.lazy(() => import("../page/Report/Wallboard/Wallboard"));
const CampaignOverviewReport = React.lazy(() => import("../page/Report/CampaignOverviewReport/CampaignOverviewReport"));
const CampaignUpdatedReport = React.lazy(() => import("../page/Report/CampaignUpdatedReport/CampaignUpdatedReport"));
const CampaignOverviewV2Report = React.lazy(() => import("../page/Report/CampaignOverviewV2Report/CampaignOverviewV2Report"));
const CampaignOverviewV3Report = React.lazy(() => import("../page/Report/CampaignOverviewV3Report/CampaignOverviewV3Report"));
const PubPerformanceReport = React.lazy(() => import("../page/Report/PubPerformanceReport/PubPerformanceReport"));
const NetworkPerformanceReport = React.lazy(() => import("../page/Report/NetworkPerformanceReport/NetworkPerformanceReport"));
// const RelPerformanceStatisticReport = React.lazy(() => import("../page/StatisticReport/RelPerformanceStatisticReport/RelPerformanceStatisticReport"))

const AUTO_LOAD_TIMER = 1000 * 60;
const SIDE_NAV_MENUS = [
    {
        "id": "default",
        "allow": "none",
        "children": [{ "id": "home", "path": "/", "className": "fas fa-home", "text": "Home", "component": <Home></Home> }]
    }
    // {
    //     "id": "99", "className": "", "text": "Bd Report", "children": [
    //         { "id": "bdreport_affiliate", "path": "/bdReport/affiliate", "className": "fas fa-tachometer-alt", "text": "Affiliate", "component": <AffiliateReport></AffiliateReport> },
    //     ]
    // },
    // {
    //     "id": "100", "className": "", "text": "HS Report", "children": [
    //         { "id": "hsreport_campaign_overview", "path": "/hsReport/campaignOverview", "className": "fas fa-tachometer-alt", "text": "Campaign Overview", "component": <CampaignOverviewHSReport></CampaignOverviewHSReport> },
    //         { "id": "hsreport_campaign_resell_overview", "path": "/hsReport/campaignResellOverview", "className": "fas fa-tachometer-alt", "text": "Campaign Resell Overview", "component": <CampaignResellOverviewHSReport></CampaignResellOverviewHSReport> },
    //         { "id": "hsreport_campaign_daily_overview", "path": "/hsReport/campaignDailyOverview", "className": "fas fa-tachometer-alt", "text": "Campaign Daily Overview", "component": <CampaignDailyOverviewHSReport></CampaignDailyOverviewHSReport> },
    //         { "id": "hsreport_deal_history", "path": "/hsReport/dealHistory", "className": "fas fa-tachometer-alt", "text": "Deal History", "component": <DealHistoryHSReport></DealHistoryHSReport> }
    //     ]
    // }
];

ORG_ALL.forEach(geo => {
    SIDE_NAV_MENUS.push({
        "id": geo,
        "className": "", "text": geo.toUpperCase(), "children": [
            {
                "id": "grp_cmdc_" + geo, "className": "", "text": "CMDC", "children": [
                    // { "id": "cmdc_campaign_monitoring", "path": "/cmdc/cpMonitoring/" + geo, "route": "/cmdc/cpMonitoring/" + geo + "/:cpId", "className": "fas fa-tachometer-alt", "text": "Campaign Monitoring", "component": <CampaignMonitoring></CampaignMonitoring> },
                    // { "id": "cmdc_agent_skill_overview", "path": "/cmdc/agentSkillOverview/" + geo, "className": "fas fa-tachometer-alt", "text": "Agent Skill", "component": <AgentSkillOverview></AgentSkillOverview> },
                    // { "id": "cmdc_agent_status", "path": "/cmdc/agStatus/" + geo, "route": "/cmdc/agStatus/" + geo + "/:cpId", "className": "fas fa-tachometer-alt", "text": "Agent Status", "component": <AgentStatus></AgentStatus> },
                    // { "id": "cmdc_statistic_call_analysis_attampt", "path": "/cmdc/attemptAnalysis/" + geo, "className": "fas fa-tachometer-alt", "text": "Attempt Analysis", "component": <AttemptAnalysis></AttemptAnalysis> }
                    // { "id": "cmdc_statistic_call_analysis_uncall", "path": "/cmdc/uncallAnalysis/" + geo, "className": "fas fa-tachometer-alt", "text": "Uncall Analysis", "component": <UncallAnalysis></UncallAnalysis> }
                ]
            },
            {
                "id": "grp_real_time_" + geo, "className": "", "text": "Real Time", "children": [
                    { "id": "wallboard_" + geo, "path": "/wallboard/" + geo, "className": "fas fa-play", "text": "Wallboard", "component": <Wallboard></Wallboard> }
                    , { "id": "daily_pubs_priority_" + geo, "path": "/report/dailyPubsPriority/" + geo, "className": "fas fa-tachometer-alt", "text": "Daily Pubs Priority", "component": <DailyPubsPriority></DailyPubsPriority> }
                    , { "id": "campaig_overview_" + geo, "path": "/report/campaigOverview/" + geo, "autoLoad": 1, "className": "fas fa-fire", "text": "Campaign Overview", "component": <CampaignOverviewReport></CampaignOverviewReport> }
                    , { "id": "campaig_overview_v2_" + geo, "path": "/report/campaigOverviewV2/" + geo, "className": "fas fa-eye", "text": "Campaign Overview V2", "component": <CampaignOverviewV2Report></CampaignOverviewV2Report> }
                    , { "id": "campaig_overview_v3_" + geo, "path": "/report/campaigOverviewV3/" + geo, "className": "fas fa-eye", "text": "Campaign Overview V3", "component": <CampaignOverviewV3Report></CampaignOverviewV3Report> }
                    , { "id": "campaig_updated_" + geo, "path": "/report/campaigUpdated/" + geo, "className": "fas fa-eye", "text": "Campaign Updated", "component": <CampaignUpdatedReport></CampaignUpdatedReport> }
                    , { "id": "pub_performance_" + geo, "path": "/report/pubPerformance/" + geo, "autoLoad": 2, "className": "fas fa-tachometer-alt", "text": "Publish Performance", "component": <PubPerformanceReport></PubPerformanceReport> }
                    , { "id": "pub_performance2_" + geo, "path": "/report/pubPerformance2/" + geo, "autoLoad": 2, "className": "fas fa-tachometer-alt", "text": "Publish Performance 2", "component": <PubPerformanceReport popLeadDist="true"></PubPerformanceReport> }
                    , { "id": "network_performance_" + geo, "path": "/report/networkPerformance/" + geo, "autoLoad": 2, "className": "fas fa-tachometer-alt", "text": "Network Performance", "component": <NetworkPerformanceReport></NetworkPerformanceReport> }
                    , { "id": "team_performance_" + geo, "path": "/report/teamPerformance/" + geo, "autoLoad": 2, "className": "fas fa-tachometer-alt", "text": "Team Performance", "component": <TeamPerformanceReport></TeamPerformanceReport> }
                    , { "id": "rel_performance_" + geo, "path": "/report/relPerformance/" + geo, "autoLoad": 2, "className": "fas fa-tachometer-alt", "text": "Team-CP Performance", "component": <RelPerformanceReport></RelPerformanceReport> }
                    , { "id": "rel_prod_performance_" + geo, "path": "/report/relProdPerformance/" + geo, "autoLoad": 2, "className": "fas fa-tachometer-alt", "text": "Prod Team perf", "component": <RelProdPerformanceReport></RelProdPerformanceReport> }
                    , { "id": "rpt_agent_today_" + geo, "path": "/report/agentToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Agent Today", "component": <AgentTodayReport></AgentTodayReport> }
                    , { "id": "rpt_agent_mtd_" + geo, "path": "/report/agentMTD/" + geo, "className": "fas fa-tachometer-alt", "text": "Agent MTD", "component": <AgentMtdReport></AgentMtdReport> }
                    , { "id": "cht_agent_today_" + geo, "path": "/chart/agentToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Agent Chart Today", "component": <AgentTodayChart></AgentTodayChart> }
                    , { "id": "cht_agent_mtd_" + geo, "path": "/chart/agentMTD/" + geo, "className": "fas fa-tachometer-alt", "text": "Agent Chart MTD", "component": <AgentMtdChart></AgentMtdChart> }
                    , { "id": "lead_dist_today_" + geo, "path": "/report/leadDistToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Lead Dist. Today", "component": <LeadDistToday></LeadDistToday> }
                    , { "id": "lead_dist_today_v2_" + geo, "path": "/report/leadDistTodayV2/" + geo, "className": "fas fa-tachometer-alt", "text": "Lead Dist. Today V2", "component": <LeadDistTodayV2></LeadDistTodayV2> }
                    , { "id": "lead_dist_today_shift_" + geo, "path": "/report/leadDistTodayShift/" + geo, "className": "fas fa-tachometer-alt", "text": "Lead Dist. Shift", "component": <LeadDistTodayShift></LeadDistTodayShift> }
                    , { "id": "lead_dist_mtd_" + geo, "path": "/report/leadDistMTD/" + geo, "className": "fas fa-tachometer-alt", "text": "Lead Dist. MTD", "component": <LeadDistMTD></LeadDistMTD> }
                    , { "id": "lead_dist_chart_today_" + geo, "path": "/chart/leadDistToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Lead Dist. Chart Today", "component": <LeadDistChartToday></LeadDistChartToday> }
                    , { "id": "lead_dist_chart_mtd_" + geo, "path": "/chart/leadDistMTD/" + geo, "className": "fas fa-tachometer-alt", "text": "Lead Dist. Chart MTD", "component": <LeadDistChartMTD></LeadDistChartMTD> }
                    // , { "id": "redis_clear_" + geo, "path": DOMAIN_API("/api/admin/redis/clear/" + geo), "className": "fas fa-trash", "text": "Refresh Cache", "menuType": "api", "allow": "all" }
                    , { "id": "rpt_team_mtd_" + geo, "path": "/report/teamMtd/" + geo, "className": "fas fa-tachometer-alt", "text": "Team Perf(MTD)", "component": <TeamMtdReport></TeamMtdReport> }
                    , { "id": "rpt_team_today_" + geo, "path": "/report/teamToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Team Perf(All)", "component": <TeamTodayReport type="teamToday"></TeamTodayReport> }
                    , { "id": "rpt_team_dist_today_" + geo, "path": "/report/teamDistToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Team Perf(New)", "component": <TeamDistTodayReport></TeamDistTodayReport> }
                    , { "id": "rpt_team_campaign_today_" + geo, "path": "/report/teamCampaignToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Team CP Perf(All)", "component": <TeamCampaignTodayReport></TeamCampaignTodayReport> }
                    , { "id": "rpt_team_campaign_dist_today_" + geo, "path": "/report/teamCampaignDistToday/" + geo, "className": "fas fa-tachometer-alt", "text": "Team CP Perf(New)", "component": <TeamCampaignDistTodayReport></TeamCampaignDistTodayReport> }
                    , { "id": "rpt_rescue_summary_" + geo, "path": "/report/rescueSummary/" + geo, "className": "fas fa-tachometer-alt", "text": "Rescue Summary", "component": <RescueSummaryReport></RescueSummaryReport> }
                    , { "id": "rpt_rescue_call_" + geo, "path": "/report/rescueCall/" + geo, "className": "fas fa-tachometer-alt", "text": "Rescue Call", "component": <RescueCallReport></RescueCallReport> }
                ]
            }, {
                "id": "grp_statistic_report_" + geo, "className": "", "text": "Statistic Report", "children": [
                    { "id": "rel_performance_statistic_report_" + geo, "path": "/statisticReport/relPerformance/" + geo, "className": "fas fa-tachometer-alt", "text": "Team-CP Performance", "component": <RelPerformanceStatisticReport></RelPerformanceStatisticReport> }
                    , { "id": "rel_prod_performance_statistic_report_" + geo, "path": "/statisticReport/relProdPerformance/" + geo, "className": "fas fa-tachometer-alt", "text": "Prod Team perf", "component": <RelProdPerformanceStatisticReport></RelProdPerformanceStatisticReport> }
                    , { "id": "pub_performance_statistic_report_" + geo, "path": "/statisticReport/pubPerformance/" + geo, "className": "fas fa-tachometer-alt", "text": "Publish Performance", "component": <PubPerformanceStatisticReport></PubPerformanceStatisticReport> }
                    , { "id": "pub_performance_statistic_report2_" + geo, "path": "/statisticReport/pubPerformance2/" + geo, "className": "fas fa-tachometer-alt", "text": "Publish Performance 2", "component": <PubPerformanceStatisticReport popLeadDist="true"></PubPerformanceStatisticReport> }
                    , { "id": "network_performance_statistic_report_" + geo, "path": "/statisticReport/networkPerformance/" + geo, "className": "fas fa-tachometer-alt", "text": "Network Performance", "component": <NetworkPerformanceStatisticReport></NetworkPerformanceStatisticReport> }
                    , { "id": "lead_dist_today_statistic_report_" + geo, "path": "/statisticReport/leadDistToday/" + geo, "route": "/statisticReport/leadDistToday/" + geo + "/:creFrom/:creTo/:prodId/:terms/:sourceId", "className": "fas fa-tachometer-alt", "text": "Lead Dist. Today", "component": <LeadDistTodayStatisticReport></LeadDistTodayStatisticReport> }
                    , { "id": "lead_dist_today_shift_statistic_report_" + geo, "path": "/statisticReport/leadDistTodayShift/" + geo, "className": "fas fa-tachometer-alt", "text": "Lead Dist. Shift", "component": <LeadDistTodayShift isDatePicker={true}></LeadDistTodayShift> }
                    , { "id": "agent_all_statistic_report_" + geo, "path": "/statisticReport/agentAll/" + geo, "className": "fas fa-tachometer-alt", "text": "Agent(All)", "component": <AgentAllStatisticReport></AgentAllStatisticReport> }
                    , { "id": "rpt_by_modified_date_" + geo, "path": "/statisticReport/byModifiedDate/" + geo, "className": "fas fa-tachometer-alt", "text": "Daily Statistic", "component": <ByModifiedDateStatisticReport></ByModifiedDateStatisticReport> }
                    , { "id": "rpt_by_create_date_" + geo, "path": "/statisticReport/byCreateDate/" + geo, "className": "fas fa-tachometer-alt", "text": "Daily Statistic(New)", "component": <ByCreateDateStatisticReport></ByCreateDateStatisticReport> }
                    , { "id": "campaig_overview_v2_statistic_report_" + geo, "path": "/statisticReport/campaigOverviewV2/" + geo, "className": "fas fa-eye", "text": "Campaign Overview V2", "component": <CampaignOverviewV2StatisticReport></CampaignOverviewV2StatisticReport> }
                    , { "id": "campaig_overview_v3_statistic_report_" + geo, "path": "/statisticReport/campaigOverviewV3/" + geo, "className": "fas fa-eye", "text": "Campaign Overview V3", "component": <CampaignOverviewV3StatisticReport></CampaignOverviewV3StatisticReport> }
                    , { "id": "lead_dist_today_v2_statistic_" + geo, "path": "/statisticReport/leadDistTodayV2/" + geo, "className": "fas fa-eye", "text": "Lead Dist. Today V2", "component": <LeadDistTodayV2Statistic></LeadDistTodayV2Statistic> }
                ]
            },
            {
                "id": "grp_logistic_" + geo, "className": "", "text": "Logistic", "children": [
                    { "id": "fulfillment_stock_" + geo, "path": "/fulfillment/stock/" + geo, "className": "fas fa-tachometer-alt", "text": "Fulfillment Stock", "component": <FulfillmentStock></FulfillmentStock> }
                    , { "id": "log_do_" + geo, "path": "/logDO/" + geo, "className": "fas fa-tachometer-alt", "text": "Log DO", "component": <LogDO></LogDO> }
                    // , { "id": "first_attempt_" + geo, "path": "/statisticReport/firstAttempt/" + geo, "className": "fas fa-tachometer-alt", "text": "First Attempt", "component": <FirstAttemptStatisticReport></FirstAttemptStatisticReport> }
                ]
            },
            {
                "id": "grp_history_" + geo, "className": "", "text": "History", "children": [
                ]
            }
        ]
    });
});

SIDE_NAV_MENUS.push({
    "id": "default_bottom",
    "allow": "all",
    "children": [{ "id": "home", "allow": "all", "path": "/login", "className": "fas fa-sign-out-alt", "text": "Sign out", "component": <React.Fragment></React.Fragment> }]
});

export { AUTO_LOAD_TIMER, SIDE_NAV_MENUS };